import Area from 'app/shared/models/Area';
import { filterUtils_getPropertyForRent } from 'app/shared/utils/filterUtils';

interface Links {
    apartmentsForRent: string;
    housesForRent: string;
    condosForRent: string;
    townhomesForRent: string;
}

export const getPropertyForRentLink = (area: Area, searchSlug: string) => {
    const links = filterUtils_getPropertyForRent(area) as Links;
    return links[searchSlug as keyof Links];
};
