// @ts-nocheck
/* eslint-enable */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swipeable } from 'app/shared/modules/navigation/Swipeable';
import { useYieldCallback } from '@zillow/yield-callback';
import { DeferredRender, RenderMode } from '@zillow/react-deferred-hydration';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import AuthActions from 'app/shared/flux/actions/AuthActions';

// Components
import LazyLoad from 'app/shared/modules/LazyLoader';
import MobileAppLink from 'app/shared/modules/mobile-apps/MobileAppLink';
import ConversationBadge from 'app/shared/modules/ConversationBadge';

// Icons
import hotpadsLogoWithText from 'images/hotpads-logo-with-text.min.svg';
import IconAlert from 'images/icons/alert.min.svg';
import IconApplications from 'images/icons/applications.min.svg';
import IconHeart from 'images/icons/heart.min.svg';
import IconMail from 'images/icons/mail-blue.min.svg';
import IconPayments from 'images/icons/payments.min.svg';
import IconPostAListing from 'images/icons/post-a-listing.min.svg';
import IconProfile from 'images/icons/profile.min.svg';
import IconSearchForRent from 'images/icons/search-for-rent.min.svg';
import IconSearchForRentHouse from 'images/icons/search-for-rent-house.min.svg';
import IconPaperPen from 'images/icons/paper-pen.min.svg';
import IconBuilding from 'images/icons/building.min.svg';
import IconTownhome from 'images/icons/property.min.svg';

// Misc / utils
import constants from 'app/shared/constants/ValueConstants';
import pathsManifest from 'app/shared/utils/pathsManifest';
import { getPropertyForRentLink } from './controller';
import { focusNextElement, focusPreviousElement, trapTabEvent } from 'app/shared/utils/focusUtils';
import { areaUtils_getBestArea } from 'app/shared/utils/areaUtils';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import * as S from './HamburgerNavStyles';

interface HamburgerNavProps {
    onCloseHamburgerNav: () => void;
}

interface RootState {
    app: {
        device: {
            screenWidth: string;
        };
        hamburger: boolean;
    };
    user: {
        loggedIn: boolean;
        unreadMsgCount: number;
    };
    location: {
        current: {
            query: {
                hostApp?: string;
            };
        };
    };
}

const HamburgerNav: React.FC<HamburgerNavProps> = ({ onCloseHamburgerNav }) => {
    const dispatch = useDispatch();
    const area = useSelector((state: RootState) => areaUtils_getBestArea(state));
    const isMobile = useSelector((state: RootState) => state.app.device.screenWidth === 'sm');
    const isHamburgerOpen = useSelector((state: RootState) => state.app.hamburger);
    const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
    const showFooter = useSelector((state: RootState) => !state.location.current.query.hostApp);
    const unreadMsgCount = useSelector((state: RootState) => state.user.unreadMsgCount);

    const hamburgerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hamburgerRef.current?.children?.[0]) {
            (hamburgerRef.current.children[0] as HTMLElement).focus();
        }
    }, [isHamburgerOpen]);

    const handleHide = useYieldCallback(() => {
        if (onCloseHamburgerNav) {
            onCloseHamburgerNav();
        }

        dispatch(AppActions.deactivateHamburger());
    });

    useEffect(() => {
        return () => {
            handleHide();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const trackGAEvent = useYieldCallback((actionName: string) => {
        dispatch(
            analyticsEvent(gaEvents.HAMBURGER_NAV_CLICK, {
                action: actionName
            })
        );
    });

    const yieldTrackAndShowLoginModal = useYieldCallback(() => {
        trackGAEvent('Sign in - Opened');
        dispatch(AppActions.showPopupModal('PromptLogin'));
    });

    const handleSignIn = () => {
        yieldTrackAndShowLoginModal();
    };

    const yieldLogout = useYieldCallback(() => {
        trackGAEvent('SignOut Click');
        dispatch(AuthActions.login.destroy());
    });

    const handleLogout = () => {
        yieldLogout();
    };

    const yieldShowFeedbackModal = useYieldCallback((actionName: string) => {
        trackGAEvent(actionName);
        dispatch(AppActions.showPopupModal('PromptFeedback'));
    });

    const handleSendFeedbackClick = ({ actionName }: { actionName: string }) => {
        yieldShowFeedbackModal(actionName);
    };

    const handleListOrMangePropertyGAEvent = (actionName: string) => {
        dispatch(
            analyticsEvent(gaEvents.LIST_OR_MANAGE_RENTALS, {
                action: actionName,
                label: 'HamburgerNav'
            })
        );
    };

    const yieldTrackAndTransitionTo = useYieldCallback(
        (path: string, actionName: string, listOrMangePropertyGaEvent = false) => {
            if (listOrMangePropertyGaEvent) {
                handleListOrMangePropertyGAEvent(actionName);
            } else {
                trackGAEvent(actionName);
            }
            window.router.transitionTo(path);
        }
    );

    const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        {
            path,
            actionName,
            listOrMangePropertyGaEvent
        }: { path: string; actionName: string; listOrMangePropertyGaEvent?: boolean }
    ) => {
        e.preventDefault();
        yieldTrackAndTransitionTo(path, actionName, listOrMangePropertyGaEvent);
    };

    const handleUserCloseHamburgerNav = useYieldCallback(() => {
        trackGAEvent('HamburgerNav Closed');
        handleHide();
    });

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === constants.DOWN_ARROW_KEYCODE) {
            focusNextElement(hamburgerRef.current);
            e.preventDefault();
        } else if (e.keyCode === constants.UP_ARROW_KEYCODE) {
            focusPreviousElement(hamburgerRef.current);
            e.preventDefault();
        } else if (e.keyCode === constants.TAB_KEYCODE) {
            trapTabEvent(hamburgerRef.current, e);
        } else if (e.keyCode === constants.ESC_KEYCODE && isHamburgerOpen) {
            handleHide();
            e.preventDefault();
        }
    };

    return (
        <Swipeable onSwipedLeft={handleUserCloseHamburgerNav}>
            <S.HamburgerMenuOverlay isHamburgerOpen={isHamburgerOpen} onClick={handleUserCloseHamburgerNav} />
            <S.HamburgerMenu isHamburgerOpen={isHamburgerOpen} onKeyDown={handleKeyDown} ref={hamburgerRef}>
                <S.LogoContainer href="/">
                    <LazyLoad height={isMobile ? '40px' : '48px'} width="158px">
                        <S.Logo
                            src={hotpadsLogoWithText}
                            alt="HotPads"
                            height={isMobile ? '40px' : '48px'}
                            width={isMobile ? '158px' : '190px'}
                        />
                    </LazyLoad>
                </S.LogoContainer>
                <S.HamburgerMenuList onClick={handleHide}>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={getPropertyForRentLink(area, 'apartmentsForRent')}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, {
                                    path: getPropertyForRentLink(area, 'apartmentsForRent'),
                                    actionName: 'SearchForRent Click'
                                })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconSearchForRent} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Apartments for rent</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={getPropertyForRentLink(area, 'housesForRent')}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, {
                                    path: getPropertyForRentLink(area, 'housesForRent'),
                                    actionName: 'SearchForRentHouse Click'
                                })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconSearchForRentHouse} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Houses for rent</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={getPropertyForRentLink(area, 'condosForRent')}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, {
                                    path: getPropertyForRentLink(area, 'condosForRent'),
                                    actionName: 'SearchForRentCondo Click'
                                })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconBuilding} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Condos for rent</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={getPropertyForRentLink(area, 'townhomesForRent')}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, {
                                    path: getPropertyForRentLink(area, 'townhomesForRent'),
                                    actionName: 'SearchForRentTownhome Click'
                                })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconTownhome} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Townhomes for rent</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={pathsManifest.MyHubPage}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, { path: pathsManifest.MyHubPage, actionName: 'MyHub Click' })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconHeart} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>My Hub</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            escape
                            to={pathsManifest.InboxPage}
                            onClick={() => trackGAEvent('SatelliteMessages Click')}
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconMail} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Messages</S.MenuSpanText>
                            {unreadMsgCount > 0 && (
                                <ConversationBadge aria-label={`Unread messages`}>{unreadMsgCount}</ConversationBadge>
                            )}
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={pathsManifest.SavedSearchPage}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, {
                                    path: pathsManifest.SavedSearchPage,
                                    actionName: 'SearchAlerts Click'
                                })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconAlert} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Search alerts</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            to={pathsManifest.PostAListingPage}
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                handleClick(e, {
                                    path: pathsManifest.PostAListingPage,
                                    actionName: 'listYourRental',
                                    listOrMangePropertyGaEvent: true
                                })
                            }
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconPostAListing} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>List your rental</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.HamburgerMenuModalButton
                            onClick={() => handleSendFeedbackClick({ actionName: 'SendFeedback Click' })}
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconPaperPen} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Send feedback</S.MenuSpanText>
                        </S.HamburgerMenuModalButton>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        {loggedIn ? (
                            <S.StyledLinker
                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                    handleClick(e, {
                                        path: pathsManifest.UserEditPage,
                                        actionName: 'UserProfile Click'
                                    })
                                }
                                to={pathsManifest.UserEditPage}
                            >
                                <S.ImgSpanContainer>
                                    <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                        <S.MenuIcon src={IconProfile} alt="" height="20px" width="20px" />
                                    </DeferredRender>
                                </S.ImgSpanContainer>
                                <S.MenuSpanText>My profile</S.MenuSpanText>
                            </S.StyledLinker>
                        ) : (
                            <S.HamburgerMenuModalButton onClick={handleSignIn}>
                                <S.ImgSpanContainer>
                                    <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                        <S.MenuIcon src={IconProfile} alt="" height="20px" width="20px" />
                                    </DeferredRender>
                                </S.ImgSpanContainer>
                                <S.MenuSpanText>Sign in / register</S.MenuSpanText>
                            </S.HamburgerMenuModalButton>
                        )}
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            escape
                            onClick={() => trackGAEvent('Payments Click')}
                            to={pathsManifest.PaymentsPage}
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconPayments} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Payments</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                    <S.HamburgerMenuListItem>
                        <S.StyledLinker
                            onClick={() => trackGAEvent('Applications Click')}
                            to={pathsManifest.ZillowApplicationsPage({
                                campaign: 'menuapplications',
                                medium: isMobile ? 'mweb' : 'web'
                            })}
                        >
                            <S.ImgSpanContainer>
                                <DeferredRender mode={RenderMode.RENDER_CLIENT_SIDE_ON_VISIBLE}>
                                    <S.MenuIcon src={IconApplications} alt="" height="20px" width="20px" />
                                </DeferredRender>
                            </S.ImgSpanContainer>
                            <S.MenuSpanText>Applications</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                </S.HamburgerMenuList>
                {showFooter && (
                    <>
                        <S.HamburgerMenuSectionHeader>Apps</S.HamburgerMenuSectionHeader>
                        <S.HamburgerMenuList onClick={handleHide}>
                            <S.HamburgerMenuListItem onClick={() => trackGAEvent('View iOS App Click')}>
                                <MobileAppLink
                                    tracker="4xxpwq"
                                    adgroup="hamburgerMenu"
                                    device="ios"
                                    trackViewNow={isHamburgerOpen}
                                >
                                    <S.MenuSpanText>iOS</S.MenuSpanText>
                                </MobileAppLink>
                            </S.HamburgerMenuListItem>
                            <S.HamburgerMenuListItem onClick={() => trackGAEvent('View Android App Click')}>
                                <MobileAppLink
                                    tracker="v9a1qr"
                                    adgroup="hamburgerMenu"
                                    device="android"
                                    trackViewNow={isHamburgerOpen}
                                >
                                    <S.MenuSpanText>Android</S.MenuSpanText>
                                </MobileAppLink>
                            </S.HamburgerMenuListItem>
                        </S.HamburgerMenuList>
                    </>
                )}

                <S.HamburgerMenuSectionHeader>Resources</S.HamburgerMenuSectionHeader>
                <S.HamburgerMenuList onClick={handleHide}>
                    <S.HamburgerMenuListItem onClick={() => trackGAEvent('Help Link Click')}>
                        <S.StyledLinker to="https://help.hotpads.com/hc/en-us">
                            <S.MenuSpanText>Knowledge Base</S.MenuSpanText>
                        </S.StyledLinker>
                    </S.HamburgerMenuListItem>
                </S.HamburgerMenuList>
                {loggedIn && (
                    <S.HamburgerMenuList onClick={handleHide}>
                        <S.HamburgerMenuListItemLogOut>
                            <S.StyledLinker to={pathsManifest.LoginPage} onClick={handleLogout}>
                                <S.MenuSpanText>Sign out</S.MenuSpanText>
                            </S.StyledLinker>
                        </S.HamburgerMenuListItemLogOut>
                    </S.HamburgerMenuList>
                )}
            </S.HamburgerMenu>
        </Swipeable>
    );
};

export default HamburgerNav;
