// App
import React from 'react';
import { useSwipeable } from 'react-swipeable';

interface Props {
    children: React.ReactNode
    onSwipedLeft?: () => void
    onSwipedRight?: () => void
    onSwipedUp?: () => void
    onSwipedDown?: () => void
    trackMouse?: boolean
    preventScrollOnSwipe?: boolean
}

/**
 * React Swipeable v6+ uses React Hooks.
 *
 * This is required for migration from v5 to v6
 *
 * @see: https://github.com/FormidableLabs/react-swipeable/blob/main/migration.md#migrate-swipeable-v5-to-v6
 */
export const Swipeable = ({ children, ...props }: Props) => {
    const handlers = useSwipeable(props);
    return <div {...handlers}>{children}</div>;
};
