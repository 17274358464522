// @ts-nocheck
/* eslint-enable */
// taken from @constellation
// https://gitlab.zgtools.net/constellation/constellation/-/blob/master/src/util/focus.js
import { tabbable } from 'tabbable';

const focusSiblingElement = (root, reverse) => {
    const elements = tabbable(root);
    if (!elements.length) {
        return;
    }

    let current = 0;
    for (; current < elements.length; current += 1) {
        if (typeof document !== 'undefined' && document.activeElement === elements[current]) {
            if (reverse) {
                current -= 1;
            } else {
                current += 1;
            }
            break;
        }
    }

    // Focuses the next/previous element in the array,
    // looping back to the beginning/end when necessary.
    elements[(current + elements.length) % elements.length].focus();
};

export const focusNextElement = (root) => focusSiblingElement(root);
export const focusPreviousElement = (root) => focusSiblingElement(root, true);

/**
 * Trap focus within the given root node.
 *
 * This will leverage native browser behavior for all events except for when the focus would leave
 * the root node.
 *
 * @param root The node to trap focus within
 * @param e The tab event to handel
 */
export const trapTabEvent = (root, e) => {
    const elements = tabbable(root);
    if (!elements.length) {
        e.preventDefault();
        return;
    }

    if ((document.activeElement === elements[0] || document.activeElement === root) && e.shiftKey) {
        // Loop backwards to the end
        elements[elements.length - 1].focus();
        e.preventDefault();
    } else if (document.activeElement === elements[elements.length - 1] && !e.shiftKey) {
        // Loop forwards to the beginning
        elements[0].focus();
        e.preventDefault();
    }
    // else let the browser handle the event natively
};
